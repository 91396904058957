import * as _setFunctionLength2 from "set-function-length";
var _setFunctionLength = _setFunctionLength2;
try {
  if ("default" in _setFunctionLength2) _setFunctionLength = _setFunctionLength2.default;
} catch (e) {}
import * as _esDefineProperty2 from "es-define-property";
var _esDefineProperty = _esDefineProperty2;
try {
  if ("default" in _esDefineProperty2) _esDefineProperty = _esDefineProperty2.default;
} catch (e) {}
import * as _callBindApplyHelpers2 from "call-bind-apply-helpers";
var _callBindApplyHelpers = _callBindApplyHelpers2;
try {
  if ("default" in _callBindApplyHelpers2) _callBindApplyHelpers = _callBindApplyHelpers2.default;
} catch (e) {}
import * as _applyBind2 from "call-bind-apply-helpers/applyBind";
var _applyBind = _applyBind2;
try {
  if ("default" in _applyBind2) _applyBind = _applyBind2.default;
} catch (e) {}
var exports = {};
var setFunctionLength = _setFunctionLength;
var $defineProperty = _esDefineProperty;
var callBindBasic = _callBindApplyHelpers;
var applyBind = _applyBind;
exports = function callBind(originalFunction) {
  var func = callBindBasic(arguments);
  var adjustedLength = originalFunction.length - (arguments.length - 1);
  return setFunctionLength(func, 1 + (adjustedLength > 0 ? adjustedLength : 0), true);
};
if ($defineProperty) {
  $defineProperty(exports, "apply", {
    value: applyBind
  });
} else {
  exports.apply = applyBind;
}
export default exports;
export const apply = exports.apply;